<template>
	<main>
		<div class="services-area grey-bg pt-100 pb-65">
			<div class="container">
				<div class="row">
					<div class="col-xl-3">
						<div class="product_wrap">
							<img src="assets/icons/truck.svg" alt="FreightMan" />
						</div>
					</div>
					<div class="col-xl-9">
						<div class="service-details mb-40">
							<img
								class="mb-30"
								src="assets/img/service/details/sevice-details.jpg"
								alt=""
							/>
							<h3>BizBank</h3>
							<p>
								Consectetuer adipiscing elit, sed diam nonummy nibh euismod
								tincidunt ut laoreet dolore mage aliquami erat volpate Ut wisi
								enim ad minim veniam, quis nostrud exerci tation corper suscipit
								lobortis nisl ut aliquip ex ea comcon sequat. Duis autem velue
								eum iriure dolor in hendrerit in vulputate velitesse molestie
								consequat.
							</p>
							<p>
								Fonsec tetuer adipiscing elitsed diam nonummy nibh euismod
								tincidunt ut laoreet dolore mage aliquami erat volpate Ut wisi
								enim ad minim.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "bizbank",
};
</script>

<style></style>
